<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <b-card-code>
                <validation-observer ref="simpleRules">
                  <form @submit.prevent="submitForm">
                    <b-row>
                      <b-col md="6">
                        <b-form-group>
                          <label>Select Date</label>
                          <flat-pickr
                            class="form-control"
                            value=""
                            placeholder="Select Date"
                            :config="{
                              dateFormat: 'd/m/Y',
                              mode: 'range',
                              maxDate: new Date(),
                            }"
                            @input="getdata($event)"
                            style="background-color: transparent"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <div class="d-flex justify-content-end">
                      <b-button
                        variant="primary"
                        class="ml-2"
                        @click="searchData"
                      >
                        <feather-icon icon="SearchIcon" class="mr-50" />
                        <span class="align-middle">Search</span>
                      </b-button>
                    </div>
                  </form>
                </validation-observer>
              </b-card-code>
              <report-table :data="data" :columns="fields" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/components/axios';
import Table from "@/components/Table.vue";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import ReportTable from "../../../components/ReportTable.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import moment from "moment";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
} from "bootstrap-vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  components: {
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    ReportTable,
  },
  data() {
    return {
      data: "",
      startdate: "",
      enddate: "",
      result: "",
      data1: [],
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      fields: [
        {
          field: "id",
          label: "S.R. No",
          hidden: true,
          // filterOptions: { enabled: true, placeholder: "Search" },
        },
        {
          field: "voucherno",
          label: "Voucher No",
          // filterOptions: { enabled: true, placeholder: "Search" },
        },
        {
          field: "chequedate",
          label: "Cheque Date",
          // filterOptions: { enabled: true, placeholder: "Search" },
        },
        {
          field: "chequeno",
          label: "Cheque No",
          // filterOptions: { enabled: true, placeholder: "Search" },
        },
        {
          field: "client",
          label: "Client Name",
          // filterOptions: { enabled: true, placeholder: "Search" },
        },
        {
          field: "clientbank",
          label: "Client Bank",
          // filterOptions: { enabled: true, placeholder: "Search" },
        },
        {
          field: "company",
          label: "Company Name",
          url: "company",
          type: "dropdown",
          responseValue: "name",
          // filterOptions: { enabled: true, placeholder: "Search" },
        },
        {
          field: "bank",
          label: "Bankname",
          // filterOptions: { enabled: true, placeholder: "Search" },
        },
        {
          field: "amount",
          label: "Amount",
          // filterOptions: { enabled: true, placeholder: "Search" },
        },
        {
          field: "reason",
          label: "Reason",
          // filterOptions: { enabled: true, placeholder: "Search" },
        },
      ],
    };
  },
  methods: {
    getdata(selectedDates) {
      this.result = selectedDates.split(" to ");
      this.startdate = this.result[0];
      this.enddate = this.result[1];
    },
    async searchData(tableData) {
      const data = {
        startdate: this.startdate,
        enddate: this.enddate,
      };
      await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: JSON.stringify(data),
        url: `${this.baseApi}/chequereport`,
      }).then((response) => {
        this.data = response.data.data;
        this.fields.map(async (item) => {
          if (item.type == "dropdown") {
            const accessToken = localStorage.getItem("accessToken");
            const baseApi = process.env.VUE_APP_APIENDPOINT;
            const requestOptionsUsers = {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
              },
              url: `${baseApi}/${item.url}`,
            };

            await axios(requestOptionsUsers).then((response) => {
              response.data.data.data
                ? (this.data1[item.url] = response.data.data.data)
                : response.data.data
                ? (this.data1[item.url] = response.data.data)
                : (this.data1[item.url] = response.data);
            });
            this.data
              .map((data) => {
                this.data1[item.url].map((data1) => {
                  if (data[item.field] == data1.id) {
                    data[item.field] = data1[item.responseValue];
                  }
                });
              })
              .catch((error) => console.log(error, "error"));
          }
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
